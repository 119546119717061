import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { VscDebugBreakpointData } from 'react-icons/vsc'


// import imgDefault from '../../assets/image/placeholder.png'



function BlockSeven({ image1, image2, image3, title, text, listAbout, listServices, sloganPrincipal }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <div className="fesbg3" data-aos="fade-left">
                <section className="w-4/5 mx-auto md:py-32 py-10 ">
                    <div className="md:flex md:flex-row flex flex-col md:p-0 px-2 mx-auto justify-center ">

                        <div className="md:w-[50%] py-4 px-3 self-center text-center bg-white rounded-3xl lg:-ml-[10%]">
                            {
                            <h2>
                                {rpdata?.dbSlogan?.[7]?.slogan}
                            </h2>
                            }
                            {
                                sloganPrincipal ?
                                    rpdata?.dbPrincipal?.licensed.length > 1 ?
                                        <h2 className="separator-about capitalize">
                                            {rpdata?.dbPrincipal?.licensed}
                                        </h2>
                                        : <h2 className="separator-about pb-3 capitalize">we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                                    :
                                    <h2 className="separator-about capitalize">
                                        {
                                            title ? title :
                                                <span className='lowercase text-[18px]'>
                                                    {`default title={'frase'}`} o
                                                    {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                                </span>
                                        }
                                    </h2>

                            }
                            {
                                listAbout ?
                                    <ul className='grid grid-cols-1 md:grid-cols-1 pb-5'>
                                        {
                                            rpdata?.dbAbout?.[2].list.length > 1 ?
                                                rpdata?.dbAbout?.[2].list.map((item, index) => {
                                                    return (

                                                        <li key={index} className="py-2 flex items-center mx-auto justify-center">
                                                            <VscDebugBreakpointData />
                                                            <span className="pl-2">{item}</span>
                                                        </li>
                                                    )
                                                })
                                                : null
                                        }
                                    </ul>
                                    : null
                            }
                            {
                                listServices ?
                                    <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                        {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                            return (
                                                <li key={index} className="py-1 flex items-center">
                                                    <VscDebugBreakpointData />
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : null
                            }

                            <ButtonContent btnStyle='three' />

                            <div className="w-full">
                                <span className="separator-about"></span>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    );
}

export default BlockSeven;
